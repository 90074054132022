<template>
    <div v-if = "isArray">
        {{ kkey }}: {{ entry.value.join(',') }}
    </div>
    <div v-else-if = "isObject">
        {{ kkey }}:
        <div class = "pl-4">
            <log-data v-for = "entry in entries" :kkey = "entry.kkey" :value = "entry.value" :key = "`${entry}`"></log-data>
        </div>
    </div>
    <div v-else>
        {{ kkey }}: {{ value }}
    </div>
</template>

<script>
    export default {
        name: 'log-data',
        props: ["kkey", "value"],
        computed: {
            isObject() {
                return typeof this.value === 'object'
            },
            isArray() {
                return Array.isArray(this.value)
            },
            entries() {
                return Object.entries(this.value).map(([kkey, value]) => {
                    return { kkey, value }
                }).sort((a) => {
                    return typeof a.value === 'object' ? 1 : -1
                })
            }
        },
        methods: {
            isItObject(a) {
                return typeof a === 'object'
            }
        }
    }
</script>