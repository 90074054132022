<template>
    <v-card class = "log" :color = "color">
        <v-card-text>
            <v-layout row pa-2>
                <v-flex v-if = "log.user">
                    <v-layout row align-center pa-4>
                        <v-flex shrink>
                            <v-avatar color = "indigo">
                                <v-img
                                    v-if="!errorLoadingImage"
                                    :src="log.user.image"
                                    @error = "noImage()"
                                ></v-img>
                                <span v-else class = "white--text">
                                    {{ log.user.username[0] }}
                                </span>
                            </v-avatar>
                        </v-flex>
                        <v-flex grow pl-4>
                            <v-layout column>
                                <h3>{{log.user.username}}</h3>
                                <h4>{{log.user.role}}</h4>
                                <h5>{{log.user.connectionId}}</h5>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex shrink>
                    <v-layout row align-center justify-center pa-4>
                        <v-icon>
                            mdi-clock
                        </v-icon>
                        <h4 class = "ml-1">
                            {{formattedDate}}
                        </h4>
                    </v-layout>
                </v-flex>
            </v-layout>
            <v-layout mt-2 v-if = "log.action" column>
                <h3>{{log.action}}</h3>
                <log-data v-for = "entry in entries" :kkey="entry.kkey" :value="entry.value" :key = "entry"></log-data>
            </v-layout>
        </v-card-text>
    </v-card>
</template>

<script>
    import moment from 'moment';
    import LogData from './LogData.vue';
    export default {
        props: ['log'],
        components: {
            LogData
        },
        data() {
            return {
                errorLoadingImage: false
            }
        },
        methods: {
            noImage() {
                this.errorLoadingImage = true
            }
        },
        computed: {
            formattedDate() {
                return moment(this.log.timestamp).format("DD. MM. YYYY HH:mm:ss")
            },
            color() {
                return this.log.level === 'error' ? '#FFEBEE' : '#FFF'
            },
            entries() {
                return Object.entries(this.log).map(([kkey, value]) => {
                    return { kkey, value }
                }).filter(obj => {
                    return !['room', 'action', 'level', 'user'].includes(obj.kkey) 
                }).sort((a) => {
                    return typeof a.value === 'object' ? 1 : -1
                })
            }
        }
    }
</script>
<style>
    .log {
        margin-bottom: 16px;
    }
</style>