<template>
    <v-container class = "logs">
        <v-layout column>
            <v-flex v-if = "!loaded">
                <v-text-field 
                    solo 
                    v-model = "roomLogs" 
                    append-icon="mdi-download" 
                    placeholder = "Specify room id"
                    @click:append = "search()"
                    :loading="loading"></v-text-field>
            </v-flex>
            <v-flex v-else>
                <v-layout row no-gutters>
                    <v-card @click = "loaded = false" class = "loadedRoom">
                        {{ roomLogs }}
                    </v-card>
                    <v-text-field 
                        hide-details
                        solo 
                        v-model = "fullTextSearch" 
                        append-icon="mdi-magnify" 
                        placeholder = "Search..."
                        height=""
                        :loading="loading"></v-text-field>
                </v-layout>
            </v-flex>
            <v-flex v-if = "error">
                <v-card
                    class="mx-auto"
                    color="error"
                    dark
                    max-width="400"
                >
                    <v-card-text>
                        {{ error }}
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex class = "logs-wrapper">
                <div class = "empty-logs" v-if = "filteredLogs.length == 0 && !loading">
                    <v-layout column align-center justify-center>
                        <v-icon :size = "56">mdi-database-off-outline</v-icon>
                        Specified logs does not exist
                    </v-layout>
                </div>
                <div class = "logs-skeleton" v-if = "loading">
                    <v-skeleton-loader
                        v-bind="attrs"
                        type="card-avatar, article, actions"
                    ></v-skeleton-loader>
                </div>
                <div class = "logs">
                    <div v-for = "(log, index) in filteredLogs" :key = "`log_${index}`">
                        <log :log="log">
                        </log>
                    </div>
                </div>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
import axios from 'axios'
import { StreamingServiceURL } from '../../../utils/consts'
import Log from './Log.vue';
export default {
    components: {
        'log': Log,
    },
    data() {
        return {
            roomLogs: '',
            loading: false,
            logs: [],
            error: null,
            loaded: false,
            fullTextSearch: ''
        }
    },
    methods: {
        search() {
            this.loading = true;
            this.error = null;
            const now = new Date().getTime();
            this.$set(this, 'logs', []);
            axios.get(
                StreamingServiceURL() + "/logs/rooms/" + this.roomLogs + ".log"
            )
            .then((logs) => {
                const diff = now - new Date().getTime()
                setTimeout(() => {
                    this.$set(this, 'logs', logs.data);
                    this.loading = false;
                    this.loaded = true;
                }, 800 - diff)
            })
            .catch((e) => {
                this.error = e;
                setTimeout(() => {
                    this.error = null;
                }, 1200)
                this.loading = false;
            })
        }
    },
    computed: {
        filteredLogs() {
            if (!this.fullTextSearch.trim()) return this.logs;
            return this.logs.filter(log => {
                return this.fullTextSearch.trim().toLowerCase().normalize('NFD').split(' ').every(part => {
                    return JSON.stringify(log).toLowerCase().normalize('NFD').includes(part)
                })
            })
        }
    },
    created() {
        const {id} = this.$route.params
        if (id) {
            this.roomLogs = id;
            this.search();
        }
    }
}
</script>
<style>
.logs-wrapper {
    margin-top:24px;
}
.loadedRoom {
    width: 48px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>